import requestResult from '@/common/request/request'
export type TRankItem = {
    userId: number;
    nickName: string;
    avatarPic: string;
    orderId: number;
    productId: string;
    productName: string;
    closeTime: string;
    createTime: string;
    createPrice: string;
    closePrice: string;
    profitability: number;
    type: number;
    amount: number;
    rankNum: number;
    voucherAmount: number | string;
}

export const readRankList = requestResult<TRankItem[]>('/api/mtrank/list')
