
import { defineComponent, shallowRef } from 'vue'
import Pic from '@/components/Pic.vue'
import ColorText from '@/components/ColorText.vue'

export default defineComponent({
  name: 'RankItem',
  components: {
    Pic,
    ColorText,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup () {
    const showMore = shallowRef(false)
    const HandleTaggle = () => {
      showMore.value = !showMore.value
    }

    return {
      showMore,
      HandleTaggle,
    }
  },
})

