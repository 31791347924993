
import { defineComponent, shallowRef } from 'vue'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import TheDateSelect from '@/pages/profitRank/components/TheDateSelect.vue'
import RankItem from '@/pages/profitRank/components/RankItem.vue'
import {
  readRankList,
  TRankItem,
} from '@/pages/profitRank/profitRank.api'
import List from '@/components/AsyncNullableList.vue'
import Holder from '@/provider/Holder.vue'
import Scroller from 'common/providers/Scroller.vue'
import { formatDateTime } from '@/common/format'

export default defineComponent({
  name: 'ProfitRank',
  components: {
    PageWithHeader,
    RankItem,
    TheDateSelect,
    List,
    Holder,
    Scroller,
  },
  setup () {
    const dateVal = shallowRef('')
    const listData = shallowRef<TRankItem[]>([])
    const dateChange = (date: string) => {
      dateVal.value = date
      refresh()
    }

    const refresh = () => {
      readRankList({ issue: dateVal.value }).then(res => {
        if (!res) {
          listData.value = []
          return
        }
        listData.value = res.map(item => {
          item.closeTime = Number.isNaN(Number(item.closeTime)) ? '--' : formatDateTime('MM-dd-yyyy HH:mm:ss', Number(item.closeTime))
          item.createTime = Number.isNaN(Number(item.createTime)) ? '--' : formatDateTime('MM-dd-yyyy HH:mm:ss', Number(item.createTime))
          item.closePrice = String(Number(item.closePrice))
          item.createPrice = String(Number(item.createPrice))
          return item
        })
      })
    }

    return {
      dateVal,
      listData,
      dateChange,
      refresh,
    }
  },
})
