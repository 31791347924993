
import { defineComponent, shallowRef } from 'vue'
import { formatDateTime } from '@/common/format'

export default defineComponent({
  name: 'TheDateSelect',
  emits: ['change'],
  setup (props, ctx) {
    const preAble = shallowRef(false)
    const nextAble = shallowRef(false)
    const dateList = [] as string[]
    const curDate = shallowRef('')
    const dayNumber = 10

    const initData = () => {
      const todayDate = new Date().valueOf()
      const aDayStamp = 24 * 60 * 60 * 1000

      for (let i = 0; i < dayNumber; i++) {
        dateList[i] = formatDateTime('MM-dd-yyyy', todayDate - (aDayStamp * i))
      }
      dateList.reverse()
      curDate.value = dateList[dayNumber - 1]
      nextAble.value = false
      preAble.value = true
      ctx.emit('change', curDate.value)
    }
    initData()

    const change = (shift: number) => {
      const cur = dateList.indexOf(curDate.value)
      if (cur + shift > -1 && cur + shift < dayNumber) {
        curDate.value = dateList[cur + shift]
        preAble.value = true
        nextAble.value = true
        if (cur + shift === 0) {
          preAble.value = false
        }
        if (cur + shift === dayNumber - 1) {
          nextAble.value = false
        }
        ctx.emit('change', curDate.value)
      }
    }

    return {
      preAble,
      nextAble,
      curDate,
      change,
    }
  },
})

